define('ember-transition-helper/helpers/transition-to', ['exports', 'ember-transition-helper/utils/transform-query-params'], function (exports, _transformQueryParams2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),

    compute([routeName, ...params]) {
      const router = this.get('router');
      (false && !(router) && Ember.assert('[ember-transition-helper] Unable to lookup router', router));

      return function (...invocationArgs) {
        const args = params.concat(invocationArgs);
        const transitionArgs = params.length ? [routeName, ...params] : [routeName];
        router.transitionTo(...(0, _transformQueryParams2.default)(transitionArgs));
        return args;
      };
    }
  });
});